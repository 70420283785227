<template>
  <div>

    <showben-info :person="person" />
    <showben-document
      v-if="person.documents"
      :person="person.documents"
    />
    <show-ben-number
      v-if="person.contact_details"
      :person="person"
    />

    <show-ben-service
      v-if="person.proposal_services"
      :personservice="person.proposal_services"
    />
    <b-card-code title="تقرير الحالة ">
      <template v-if="reports.length > 0">
        <b-row v-for="(service, index) in reports" :key="index">
          <b-col md="6" xl="4">
            <b-form-group label-for="date">
              <label>تاريخ التواصل</label>
              <h5 class="text-capitalize mb-75">
                {{ service.date }}
              </h5>
            </b-form-group>
          </b-col>
          <b-col md="12" xl="4">
            <b-form-group label-for="notes">
              <label>الملاحظات</label>
              <h5 class="text-capitalize mb-75">
               
                <div v-html="service.notes"></div>
              </h5>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
     
   
    </b-card-code>
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      @click="$router.go(-1)"
    >
      <span class="align-middle">رجوع</span>
    </b-button>
  </div>
</template>

<script>

import Ripple from 'vue-ripple-directive'
import { BButton } from 'bootstrap-vue'
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";


import showbenInfo from './show-ben-info.vue'
import showbenDocument from './show-ben-document.vue'
import showBenNumber from './show-ben-number.vue'
import showBenService from './show-ben-service'

export default {
  components: {
    BRow, BCol, BFormGroup,
    BCardCode,
    showBenService,
    showbenInfo,
    showBenNumber,
    showbenDocument,
    BButton,

  },

  directives: {
    Ripple,
  },

  data() {
    return {
      person: {},
      reports:[]
    }
  },

  created() {
    this.get()
  },

  methods: {
    get() {
      const url = `/api/v1/service_maintainer/offered_services/${this.$route.params.id}`
      // console.log(this.$route.params.id);
      this.$http.get(url).then(res => {
        console.log("data",res.data.data);

        this.reports = res.data.data.reports
        this.person = res.data.data.person
      })
      // //console.log(this.personWeak);
    },

  },
}
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
